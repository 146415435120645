import script from "./Outputs.vue?vue&type=script&setup=true&lang=js"
export * from "./Outputs.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QEditor from 'quasar/src/components/editor/QEditor.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QItem,QItemSection,QItemLabel,QCard,QScrollArea,QCardSection,QTooltip,QEditor,QBtn});
